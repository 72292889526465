@import url("https://fonts.googleapis.com/css?family=Baloo+Thambi&subset=latin-ext,tamil,vietnamese");
@import url("https://fonts.googleapis.com/css?family=Montserrat:200,300,400,500&display=swap");
@import "/node_modules/react-vis/dist/style";

@font-face {
  font-family: "telugu_mn";
  src: local("MyFont"), url(/fonts/TeluguMN-Bold.woff2) format("woff");
}

@font-face {
  font-family: "telugu_reg";
  src: local("MyFont"), url(/fonts/telugumn-regular.woff2) format("woff");
}
