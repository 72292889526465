
img.dashboard-logo {
  position: absolute;
  top: -47px;
  right: 0;
}

.dashboard {
  width: 100%;
}

.dashboard.admin{
  display: flex;
}

.right-menu {
  width: 240px;
  background: #005290;
}

.main-content {
  flex: 1;
  padding: 40px;
}

.right-menu a {
  margin-top: 22px;
  display: block;
  font-size: 18px;
  color: #fff;
  text-align: center;
}

.ant-collapse-header {
  background: #f0f0f0;
}

.conatiner-dashboard {
  height: 100vh;
}


.active_menu {
  background: #fff;
  color: #000 !important;
}

.dashboard th,
.dashboard td {
  text-align: right !important;
}

.dashboard td {
  padding: 10px !important;
}

.image-table {
  max-width: 100px;
  width: 100px !important;
  height: 100px;
  object-fit: cover;
  background-size: 100%;
  background-position: 100%;
  background-repeat: no-repeat;
  cursor: pointer;
}

.image-table:hover {
  background-image: url(https://maxcdn.icons8.com/app/uploads/2016/10/edit2.png) !important;
}

.ant-btn-link {
  border: none !important;
  color: #2b69cf !important;
  font-weight: bold !important;
}

.editable-row .ant-form-explain {
  position: absolute;
  font-size: 12px;
  margin-top: -4px;
}

/* events page only*/

.events button.ant-btn.ant-btn-link {
  display: none;
}

.events .ant-table-bordered .ant-table-thead > tr > th,
.events .ant-table-bordered .ant-table-tbody > tr > td {
  border-right: none !important;
}

i.anticon.anticon-close.ant-modal-close-icon {
  color: #001d32;
}

div#rcDialogTitle1 {
  margin-right: 20px;
}

.ant-table-title {
  border: none !important;
  padding-right: 0px !important;
}

.dashboard button.ant-btn.ant-btn-link {
  border: 1px solid #005290 !important;
  margin-left: 10px;
  color: #005290 !important;
}

.dashboard button.ant-btn.ant-btn-primary.ant-btn-sm {
  height: 30px;
  text-align: center;
  padding: 0px !important;
  background: #005290;
  border-color: #005290;
}

.dashboard th {
  background: #f38f4f !important;
}

.ant-modal-title {
  padding-right: 30px;
}

.ant-select-selection-selected-value {
  float: right;
}

i.anticon.anticon-calendar.ant-calendar-picker-icon {
  position: absolute;
  left: 7px !important;
  right: inherit;
}

@media only screen and (min-width: 310px) and (max-width: 1450px) {
  .ant-tabs-content.ant-tabs-content-animated.ant-tabs-right-content {
    padding: 0px !important;
  }

  .conatiner-dashboard {
    margin: 0px !important;
    padding: 0px !important;
    width: 100% !important;
    float: left;
    max-width: 100% !important;
  }
}
