.header {
  padding: 15px;
  box-shadow: 0 4px 2px -2px #8080802b;
}

.menu-modal a {
  float: right;
  font-size: 22px;
  width: 100%;
  margin-bottom: 29px;
  color: #fff;
  text-align: center;
}

.ant-modal.menu-modal .ant-modal-content {
  background: #000000ad;
}
