* {
  font-family: "Montserrat", sans-serif;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.block {
  display: block !important;
}
.App-link {
  color: #61dafb;
}

.App-Nav {
  background-color: "red";
  display: "none";
}
.imageInMiddle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 250px;
}

.admin-menu .sc-EHOje.chXdSi {
  background: black;
}

.buttun-download button.ant-btn.ant-btn-link span {
  margin: 3px 9px;
}

.buttun-download i.anticon.anticon-file-excel.f4.cursor.marginRight5 {
  margin: 3px !important;
}

.buttun-download button.ant-btn.circle.ant-btn-link {
  width: 114px;
  display: flex;
  margin: 0 auto;
}

.ant-table-row td:nth-child(2),
.ant-table-thead th:nth-child(2) {
  display: none;
}

.dashboard-table .ant-table-row td:nth-child(2),
.dashboard-table .ant-table-thead th:nth-child(2) {
  display: block;

  line-height: 3;
}

.ant-upload-list-picture-card .ant-upload-list-item-error,
.ant-upload-list-item-error {
  border-color: #d9d9d9 !important;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.b-cover {
  background-size: 100% 100%;
}

.error-row-duplicates {
  max-height: 200px;
  overflow-y: auto;
}
