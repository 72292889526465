.ProgectMain .ant-tabs-tab {
  width: 20%;
  margin: 0px !important;
}

.ProgectMain .ant-tabs-tabpane.ant-tabs-tabpane-active {
  position: absolute;
  margin: 0 auto;
}

.ProgectMain .ant-tabs-ink-bar {
  display: none !important;
}

.ProgectMain .ant-tabs-nav.ant-tabs-nav-animated {
  width: 100%;
  background: #fff;
}

.scroll {
  overflow: scroll;
  height: 98vh;
}
.ProgectMain i {
  font-size: 23px;
  margin: 0 auto !important;
  display: block;
}

.ProgectMain .ant-tabs-content {
  overflow-y: scroll;
}

.ProgectMain .ant-tabs.ant-tabs-bottom.ant-tabs-line {
  height: calc(100vh - 95px);
}

.ProgectMain .ant-tabs-bar.ant-tabs-bottom-bar {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.textCircle {
  position: absolute;
  text-align: center;
  top: 41px;
  right: 0;
  width: 100%;
}
