.ReactGridGallery_tile-viewport {
  border-radius: 14px;
}

.row-gallery .black.f4 {
  display: inline-block;
  margin: 25px 20px !important;
}

.row-gallery.full {
  clear: both;
}

/* .grid.grid2 a {
  clear: both;
  border: 1px solid #ac9f9f4d;
  padding: 34px;
  left: 0;
  margin: 0 auto;
  right: 0;
  width: 86%!important;
  height: 145px;
} */


.row-gallery a {
  min-height: 145px;
}

.file-name {
  font-size: 10px;
  position: static;
  bottom: 0;
  font-weight: 400;
  width: 61%;
  margin: 1px auto;
  display: block;
}

.file-icon {
  top: 50px;
  position: absolute;
  right: 0;
  left: 0;
  font-size: 51px !important;
  color: red !important;
}
