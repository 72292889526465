@import url(https://fonts.googleapis.com/css?family=Baloo+Thambi&subset=latin-ext,tamil,vietnamese);
@import url(https://fonts.googleapis.com/css?family=Montserrat:200,300,400,500&display=swap);
@import url(/node_modules/react-vis/dist/style);
body {
  margin: 0 !important;
  direction: rtl;
}

i.anticon.anticon-menu {
  float: left;
  margin-left: 10px;
}

.scroll::-webkit-scrollbar {
  width: 10px !important;
}

.scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 10px;
}

.ltr {
  direction: ltr;
}

.header {
  padding: 15px;
  box-shadow: 0 4px 2px -2px #8080802b;
  position: fixed;
  width: 100%;
  background: #fff;
  z-index: 99;
  top: 0px;
  right: 0px;
}

.__react_modal_image__header {
  z-index: 999999;
}

.scrollbar {
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  height: calc(100vh - 0px);
  object-fit: cover !important;
}

/* .screen {
  width: 100%;
  height: 88vh;
  position: absolute;
  background: #141212ab;
} */

.ant-modal-close-x svg {
  color: #fff;
  font-size: 28px;
}

.ant-select-selection-selected-value {
  float: right !important;
}

.ant-select-selection__placeholder {
  text-align: right !important;
  right: 0 !important;
}

span.ant-select-arrow {
  left: 0px !important;
}

.anticon.anticon-down.ant-select-arrow-icon {
  width: 16px;
  float: left;
  padding-left: 20px;
}

.container {
  max-width: 90%;
  height: 98vh;
  position: absolute;
  top: 0;
  margin: 0 auto;
  left: 0;
  width: 450px;
  right: 0;
}

.container.auth_container {
  overflow: hidden;
}

.container.top {
  top: 103px;
  height: calc(100vh - 115px);
}

.conatiner-bottom {
  margin-bottom: 200px;
}

.menu-modal button.ant-modal-close {
  position: static;
  margin: 0 auto;
  width: 49px;
  display: block;
}

.content {
  height: calc(100vh - 95px);
}

.ant-card {
  border-radius: 12px !important;
}

/* colors */
.white {
  color: #fff !important;
}

i.anticon.anticon-inbox {
  display: none;
}

.black {
  color: #000 !important;
}

.black svg {
  color: #000 !important;
}

.gray {
  color: #b2b3b3 !important;
}
.blue {
  color: #00538b !important;
}

.blueT {
  color: #2a689457 !important;
}

.red {
  color: #d9002b !important;
}

.red2 {
  color: #cc4c4c !important;
}

.green {
  color: #60cb4c !important;
}

.cursor {
  cursor: pointer !important;
}

/* background */

.b-white {
  background: #fff !important;
}

.b-gray {
  background: #f0f2f5 !important;
}

.b-gray-t {
  background: #ffffffb8 !important;
}

.b-blue {
  background: #00538b !important;
}

.b-blueT {
  background: #2a689457 !important;
}

.b-red {
  background: #d9002b !important;
}

.b-red2 {
  background: #cc4c4c !important;
}

.b-green {
  background: #60cb4c !important;
}

/* border */

.br-gray {
  border-color: #f0f2f5 !important;
}

.br-blue {
  border-color: #00538b !important;
}

.br-blueT {
  border-color: #2a689457 !important;
}

.br-red {
  border-color: #d9002b !important;
}

.br-red2 {
  border-color: #cc4c4c !important;
}

.br-green {
  border-color: #60cb4c !important;
}

.radius {
  border-radius: 6px !important;
}

.circle {
  border-radius: 50px !important;
}

/* width */
.full {
  width: 100% !important;
}
.col-12 {
  width: 50%;
}

.col-11 {
  width: 45.83%;
}

.col-10 {
  width: 41.666%;
}

.col-9 {
  width: ‭37.5‬%;
}

.col-8 {
  width: 33.3333%;
}

.col-7 {
  width: 45.83%;
}

/* image */

.imageFull {
  max-height: 300px;
  object-fit: cover;
  width: 100%;
}

.imageHalf {
  max-height: 150px;
  width: 100%;
  object-fit: cover;
}

/* border */
.no-border {
  border: none !important;
}

/* float */
.fLeft {
  float: left !important;
}

.fright {
  float: right !important;
}

/* button */

.buttonSquere {
  width: 50px;
}

/* padding */

.no-padding {
  padding: 0px !important;
}
.padding10 {
  padding: 10px !important;
}

.paddingVer20 {
  padding-right: 20px !important;
  padding-left: 20px !important;
}

.paddingVer40 {
  padding-right: 40px !important;
  padding-left: 40px !important;
}
.paddingVer10 {
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.paddingHor20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.padding20 {
  padding: 20px !important;
}

.marginCenter {
  margin: 0 auto !important;
}

/* margin */
.margin10 {
  margin: 10px;
}

.marginTop10 {
  margin-top: 10px !important;
}

.marginTop20 {
  margin-top: 20px !important;
}

.marginBot20 {
  margin-bottom: 20px !important;
}

.marginVer20 {
  margin-left: 20px;
  margin-right: 20px;
}

.marginHor20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.marginVer5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.marginHor10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.margin20 {
  margin: 20px !important;
}

.marginLeft5 {
  margin-left: 5px !important;
}

.marginLeft20 {
  margin-left: 20px !important;
}
.marginRight10 {
  margin-right: 10px !important;
}

.marginRight5 {
  margin-right: 5px !important;
}

.marginRight20 {
  margin-right: 20px !important;
}

.marginRight40 {
  margin-right: 40px !important;
}

/* font */
.bold {
  font-weight: bold;
}

.fw400 {
  font-weight: 400 !important;
}

.f1 {
  font-size: 25px;
}

.f2 {
  font-size: 20px;
}

.f3 {
  font-size: 18px;
}

.f4 {
  font-size: 14px;
}

.f5 {
  font-size: 10px;
}

.f16 {
  font-size: 16px;
}

.f20 {
  font-size: 20px;
}

.link {
  color: #0a60c1;
  text-decoration: underline;
}

/* align */
.textCenter {
  text-align: center !important;
}

.textLeft {
  text-align: left !important;
}

.textRight {
  text-align: right !important;
}

/* display */
.show {
  display: block !important;
}

.flex {
  display: flex !important;
}

.flex1 {
  flex: 1 1;
}



.column{
  flex-direction: column;
}

.ant-drawer-open button.ant-drawer-close {
  left: 0px;
  right: inherit;
}

.relative {
  position: relative !important;
}

.grid {
  display: grid !important;
}

.grid2 {
  grid-template-columns: 1fr 1fr;
}

.grid .image-grid {
  object-fit: cover;
  width: 100%;
  height: 200px;
  padding: 20px;
}

i.anticon.anticon-search.ant-table-filter-icon.ant-dropdown-trigger {
  color: white;
  font-size: 12px;
}

.contents {
  display: contents;
}

.hide {
  display: none !important;
}

/* modal */

.ant-modal-body {
  display: flow-root;
}

.ant-modal-body button.ant-modal-close,
.ant-modal-close-x {
  color: #fff;
}

.ant-tabs-tabpane.ant-tabs-tabpane-active.padding20 {
  padding: 0px !important;
}

.background-image {
  background-repeat: no-repeat;
  background-size: 100% 153%;
  position: relative;
  height: 98vh;
}

.ant-drawer-content-wrapper {
  width: 500px !important;
}

.screen {
  width: 100%;
  height: 100vh;
  opacity: 0.3;
  position: absolute;
  z-index: 2;
  background: white;
}

.main.home_page {
  position: relative;
  z-index: 1;
}

.payment-req button.ant-modal-close {
  display: none;
}

* {
  font-family: "Montserrat", sans-serif;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.block {
  display: block !important;
}
.App-link {
  color: #61dafb;
}

.App-Nav {
  background-color: "red";
  display: "none";
}
.imageInMiddle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 250px;
}

.admin-menu .sc-EHOje.chXdSi {
  background: black;
}

.buttun-download button.ant-btn.ant-btn-link span {
  margin: 3px 9px;
}

.buttun-download i.anticon.anticon-file-excel.f4.cursor.marginRight5 {
  margin: 3px !important;
}

.buttun-download button.ant-btn.circle.ant-btn-link {
  width: 114px;
  display: flex;
  margin: 0 auto;
}

.ant-table-row td:nth-child(2),
.ant-table-thead th:nth-child(2) {
  display: none;
}

.dashboard-table .ant-table-row td:nth-child(2),
.dashboard-table .ant-table-thead th:nth-child(2) {
  display: block;

  line-height: 3;
}

.ant-upload-list-picture-card .ant-upload-list-item-error,
.ant-upload-list-item-error {
  border-color: #d9d9d9 !important;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.b-cover {
  background-size: 100% 100%;
}

.error-row-duplicates {
  max-height: 200px;
  overflow-y: auto;
}

@font-face {
  font-family: "telugu_mn";
  src: local("MyFont"), url(/fonts/TeluguMN-Bold.woff2) format("woff");
}

@font-face {
  font-family: "telugu_reg";
  src: local("MyFont"), url(/fonts/telugumn-regular.woff2) format("woff");
}

.header {
  padding: 15px;
  box-shadow: 0 4px 2px -2px #8080802b;
}

.menu-modal a {
  float: right;
  font-size: 22px;
  width: 100%;
  margin-bottom: 29px;
  color: #fff;
  text-align: center;
}

.ant-modal.menu-modal .ant-modal-content {
  background: #000000ad;
}


.CardAddedFiles .ant-card {
  border: none;
}

.inline {
  display: inline-flex !important;
}

.hoverCard {
  width: 25%;
  height: 100px;
  position: absolute;
  left: 0;
  background: #47628482;
  color: #fff;
  font-size: 22px;
  text-align: center;
  line-height: 4.5;
}


    .contact{
      
    }
    
.Feed {
}



    .FindAsset{
      
    }
    
.MyAssets {
}
span.block,
a.block {
  color: black;
}


    .MyProjects{
      
    }
    
.active_payment_btn {
    background: #cc4c4c!important;
    color: #fff!important;
}
    

    .PaymentRequirements{
      
    }
    
.ant-carousel .slick-slide {
  text-align: center;
  overflow: hidden;
}

.ant-carousel .slick-slide h3 {
  color: #fff;
}

.slick-slide.slick-active {
  height: 325px !important;
}

.slick-slide img {
  height: 325px !important;
  object-fit: cover;
}


    .paymentBadge{
      
    }
    
.ProgectMain .ant-tabs-tab {
  width: 20%;
  margin: 0px !important;
}

.ProgectMain .ant-tabs-tabpane.ant-tabs-tabpane-active {
  position: absolute;
  margin: 0 auto;
}

.ProgectMain .ant-tabs-ink-bar {
  display: none !important;
}

.ProgectMain .ant-tabs-nav.ant-tabs-nav-animated {
  width: 100%;
  background: #fff;
}

.scroll {
  overflow: scroll;
  height: 98vh;
}
.ProgectMain i {
  font-size: 23px;
  margin: 0 auto !important;
  display: block;
}

.ProgectMain .ant-tabs-content {
  overflow-y: scroll;
}

.ProgectMain .ant-tabs.ant-tabs-bottom.ant-tabs-line {
  height: calc(100vh - 95px);
}

.ProgectMain .ant-tabs-bar.ant-tabs-bottom-bar {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.textCircle {
  position: absolute;
  text-align: center;
  top: 41px;
  right: 0;
  width: 100%;
}

.ReactGridGallery_tile-viewport {
  border-radius: 14px;
}

.row-gallery .black.f4 {
  display: inline-block;
  margin: 25px 20px !important;
}

.row-gallery.full {
  clear: both;
}

/* .grid.grid2 a {
  clear: both;
  border: 1px solid #ac9f9f4d;
  padding: 34px;
  left: 0;
  margin: 0 auto;
  right: 0;
  width: 86%!important;
  height: 145px;
} */


.row-gallery a {
  min-height: 145px;
}

.file-name {
  font-size: 10px;
  position: static;
  bottom: 0;
  font-weight: 400;
  width: 61%;
  margin: 1px auto;
  display: block;
}

.file-icon {
  top: 50px;
  position: absolute;
  right: 0;
  left: 0;
  font-size: 51px !important;
  color: red !important;
}


    .gridCard{
      
    }
    
.ant-calendar-picker {
  width: 100%;
}

.select-date-eport .ant-calendar-cell {
  padding: 0px;
}


    .Reports{
      
    }
    
.bar-precent:first-child {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.bar-precent:last-child {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.scroll-me {
  height: calc(100vh - 163px);
  overflow-y: scroll;
  margin-bottom: auto;
}

.ant-collapse.ant-collapse-borderless {
  background: transparent;
}

.ant-collapse-item {
  background: #fff !important;
}

.QA {
}

.ProgectWrapper .ant-tabs-tab {
  width: 20%;
  margin: 0px !important;
}

.ProgectWrapper .ant-tabs-tabpane.ant-tabs-tabpane-active {
  position: absolute;
}

.ProgectWrapper .ant-tabs-ink-bar {
  display: none !important;
}

.ProgectWrapper .ant-tabs-nav.ant-tabs-nav-animated {
  width: 100%;
  background: #fff;
  height: 65px;
}

.ant-tabs-tab i {
  font-size: 30px !important;
  line-height: 1.5;
}

.scroll {
  overflow: auto;
  height: 98vh;
  padding-bottom: 200px;
}
.ProgectWrapper i {
  font-size: 23px;
  margin: 0 auto !important;
  display: block;
}

.ProgectWrapper .ant-tabs-content {
  overflow-y: scroll;
}

.ProgectWrapper .ant-tabs.ant-tabs-bottom.ant-tabs-line {
  height: calc(100vh - 95px);
}

.ProgectWrapper .ant-tabs-bar.ant-tabs-bottom-bar {
  position: absolute;
  bottom: 0;
  width: 100%;
}


    .Settings{
      
    }
    

    .list{
      
    }
    
.img-thumb {
  height: 126px;
  margin-bottom: 20px;
  object-fit: cover;
}

.main-image-library .img-wrapper {
  width: 30.9333%;
  height: 138px;
  margin: 7px;
  float: left;
  margin-bottom: 68px;
  margin-top: 33px;
  position: relative;
}
.img-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin-bottom: 17px;
}

.media i.anticon.anticon-inbox {
  margin-right: 33px;
  position: absolute;
  right: 20px;
  top: 33px;
}

.ant-upload.ant-upload-drag {
  margin: 0px !important;
}

.ant-upload.ant-upload-drag {
  position: static;
  margin-top: 65px;
  max-height: 111px !important;
}

.main-image-library {
  position: relative;
  display: block;
}

.folder-name {
  margin-right: 8px;
}

.folder {
  width: 177px;
  border: 1px solid;
  margin: 10px 0px;
  border-radius: 7px;
}

.react-contextmenu-item {
  top: 0px;
  z-index: 99999999;
  background: #00538b;
  padding: 19px;
  color: #fff;
  cursor: pointer;
}

.media i {
  float: right;
  margin-left: 10px;
}

.react-contextmenu-wrapper {
  width: 100%;
  padding: 6px;
}
nav.react-contextmenu.react-contextmenu--visible {
  border: none;
  border-radius: 2px;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  display: block;
  margin-top: -10px;
  max-width: 448px;
  min-width: 320px;
  opacity: 0;
  outline: 1px solid transparent;
  overflow-y: hidden;
  padding: 0;
  pointer-events: none;
  transition: height 267ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    margin-top 267ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 267ms cubic-bezier(0.4, 0, 0.2, 1);
  will-change: height, margin-top, opacity;
  z-index: 999;
}


.media .img-wrapper .anticon.anticon-delete {
  background: #827979ab;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  text-align: center;
  padding-top: 60px;
  font-size: 30px;
  color: #fffdfd!important;
}

.media .ant-upload-list-item-info .ant-upload-list-item-card-actions {
  left: 0px;
  right: inherit;
  position: absolute;
  padding-top: 5px;
}

.hidden{
  display :none;
}
.qaEdit {
}

.add-record svg {
  color: black !important;
  font-size: 14px !important;
}

i.anticon.anticon-close.ant-modal-close-icon {
  float: right;
  padding: 20px;
}


img.dashboard-logo {
  position: absolute;
  top: -47px;
  right: 0;
}

.dashboard {
  width: 100%;
}

.dashboard.admin{
  display: flex;
}

.right-menu {
  width: 240px;
  background: #005290;
}

.main-content {
  flex: 1 1;
  padding: 40px;
}

.right-menu a {
  margin-top: 22px;
  display: block;
  font-size: 18px;
  color: #fff;
  text-align: center;
}

.ant-collapse-header {
  background: #f0f0f0;
}

.conatiner-dashboard {
  height: 100vh;
}


.active_menu {
  background: #fff;
  color: #000 !important;
}

.dashboard th,
.dashboard td {
  text-align: right !important;
}

.dashboard td {
  padding: 10px !important;
}

.image-table {
  max-width: 100px;
  width: 100px !important;
  height: 100px;
  object-fit: cover;
  background-size: 100%;
  background-position: 100%;
  background-repeat: no-repeat;
  cursor: pointer;
}

.image-table:hover {
  background-image: url(https://maxcdn.icons8.com/app/uploads/2016/10/edit2.png) !important;
}

.ant-btn-link {
  border: none !important;
  color: #2b69cf !important;
  font-weight: bold !important;
}

.editable-row .ant-form-explain {
  position: absolute;
  font-size: 12px;
  margin-top: -4px;
}

/* events page only*/

.events button.ant-btn.ant-btn-link {
  display: none;
}

.events .ant-table-bordered .ant-table-thead > tr > th,
.events .ant-table-bordered .ant-table-tbody > tr > td {
  border-right: none !important;
}

i.anticon.anticon-close.ant-modal-close-icon {
  color: #001d32;
}

div#rcDialogTitle1 {
  margin-right: 20px;
}

.ant-table-title {
  border: none !important;
  padding-right: 0px !important;
}

.dashboard button.ant-btn.ant-btn-link {
  border: 1px solid #005290 !important;
  margin-left: 10px;
  color: #005290 !important;
}

.dashboard button.ant-btn.ant-btn-primary.ant-btn-sm {
  height: 30px;
  text-align: center;
  padding: 0px !important;
  background: #005290;
  border-color: #005290;
}

.dashboard th {
  background: #f38f4f !important;
}

.ant-modal-title {
  padding-right: 30px;
}

.ant-select-selection-selected-value {
  float: right;
}

i.anticon.anticon-calendar.ant-calendar-picker-icon {
  position: absolute;
  left: 7px !important;
  right: inherit;
}

@media only screen and (min-width: 310px) and (max-width: 1450px) {
  .ant-tabs-content.ant-tabs-content-animated.ant-tabs-right-content {
    padding: 0px !important;
  }

  .conatiner-dashboard {
    margin: 0px !important;
    padding: 0px !important;
    width: 100% !important;
    float: left;
    max-width: 100% !important;
  }
}

.error{color:red;font-size:12px;margin-top:10px}

