.img-thumb {
  height: 126px;
  margin-bottom: 20px;
  object-fit: cover;
}

.main-image-library .img-wrapper {
  width: 30.9333%;
  height: 138px;
  margin: 7px;
  float: left;
  margin-bottom: 68px;
  margin-top: 33px;
  position: relative;
}
.img-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin-bottom: 17px;
}

.media i.anticon.anticon-inbox {
  margin-right: 33px;
  position: absolute;
  right: 20px;
  top: 33px;
}

.ant-upload.ant-upload-drag {
  margin: 0px !important;
}

.ant-upload.ant-upload-drag {
  position: static;
  margin-top: 65px;
  max-height: 111px !important;
}

.main-image-library {
  position: relative;
  display: block;
}

.folder-name {
  margin-right: 8px;
}

.folder {
  width: 177px;
  border: 1px solid;
  margin: 10px 0px;
  border-radius: 7px;
}

.react-contextmenu-item {
  top: 0px;
  z-index: 99999999;
  background: #00538b;
  padding: 19px;
  color: #fff;
  cursor: pointer;
}

.media i {
  float: right;
  margin-left: 10px;
}

.react-contextmenu-wrapper {
  width: 100%;
  padding: 6px;
}
nav.react-contextmenu.react-contextmenu--visible {
  border: none;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -webkit-box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  display: block;
  margin-top: -10px;
  max-width: 448px;
  min-width: 320px;
  opacity: 0;
  outline: 1px solid transparent;
  overflow-y: hidden;
  padding: 0;
  pointer-events: none;
  -webkit-transition: height 267ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    margin-top 267ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 267ms cubic-bezier(0.4, 0, 0.2, 1);
  transition: height 267ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    margin-top 267ms cubic-bezier(0.4, 0, 0.2, 1),
    opacity 267ms cubic-bezier(0.4, 0, 0.2, 1);
  will-change: height, margin-top, opacity;
  z-index: 999;
}


.media .img-wrapper .anticon.anticon-delete {
  background: #827979ab;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  text-align: center;
  padding-top: 60px;
  font-size: 30px;
  color: #fffdfd!important;
}

.media .ant-upload-list-item-info .ant-upload-list-item-card-actions {
  left: 0px;
  right: inherit;
  position: absolute;
  padding-top: 5px;
}

.hidden{
  display :none;
}