.ProgectWrapper .ant-tabs-tab {
  width: 20%;
  margin: 0px !important;
}

.ProgectWrapper .ant-tabs-tabpane.ant-tabs-tabpane-active {
  position: absolute;
}

.ProgectWrapper .ant-tabs-ink-bar {
  display: none !important;
}

.ProgectWrapper .ant-tabs-nav.ant-tabs-nav-animated {
  width: 100%;
  background: #fff;
  height: 65px;
}

.ant-tabs-tab i {
  font-size: 30px !important;
  line-height: 1.5;
}

.scroll {
  overflow: auto;
  height: 98vh;
  padding-bottom: 200px;
}
.ProgectWrapper i {
  font-size: 23px;
  margin: 0 auto !important;
  display: block;
}

.ProgectWrapper .ant-tabs-content {
  overflow-y: scroll;
}

.ProgectWrapper .ant-tabs.ant-tabs-bottom.ant-tabs-line {
  height: calc(100vh - 95px);
}

.ProgectWrapper .ant-tabs-bar.ant-tabs-bottom-bar {
  position: absolute;
  bottom: 0;
  width: 100%;
}
