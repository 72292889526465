.ant-carousel .slick-slide {
  text-align: center;
  overflow: hidden;
}

.ant-carousel .slick-slide h3 {
  color: #fff;
}

.slick-slide.slick-active {
  height: 325px !important;
}

.slick-slide img {
  height: 325px !important;
  object-fit: cover;
}
