body {
  margin: 0 !important;
  direction: rtl;
}

i.anticon.anticon-menu {
  float: left;
  margin-left: 10px;
}

.scroll::-webkit-scrollbar {
  width: 10px !important;
}

.scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 10px;
}

.ltr {
  direction: ltr;
}

.header {
  padding: 15px;
  box-shadow: 0 4px 2px -2px #8080802b;
  position: fixed;
  width: 100%;
  background: #fff;
  z-index: 99;
  top: 0px;
  right: 0px;
}

.__react_modal_image__header {
  z-index: 999999;
}

.scrollbar {
  background-repeat: no-repeat;
  position: relative;
  width: 100%;
  height: calc(100vh - 0px);
  object-fit: cover !important;
}

/* .screen {
  width: 100%;
  height: 88vh;
  position: absolute;
  background: #141212ab;
} */

.ant-modal-close-x svg {
  color: #fff;
  font-size: 28px;
}

.ant-select-selection-selected-value {
  float: right !important;
}

.ant-select-selection__placeholder {
  text-align: right !important;
  right: 0 !important;
}

span.ant-select-arrow {
  left: 0px !important;
}

.anticon.anticon-down.ant-select-arrow-icon {
  width: 16px;
  float: left;
  padding-left: 20px;
}

.container {
  max-width: 90%;
  height: 98vh;
  position: absolute;
  top: 0;
  margin: 0 auto;
  left: 0;
  width: 450px;
  right: 0;
}

.container.auth_container {
  overflow: hidden;
}

.container.top {
  top: 103px;
  height: calc(100vh - 115px);
}

.conatiner-bottom {
  margin-bottom: 200px;
}

.menu-modal button.ant-modal-close {
  position: static;
  margin: 0 auto;
  width: 49px;
  display: block;
}

.content {
  height: calc(100vh - 95px);
}

.ant-card {
  border-radius: 12px !important;
}

/* colors */
.white {
  color: #fff !important;
}

i.anticon.anticon-inbox {
  display: none;
}

.black {
  color: #000 !important;
}

.black svg {
  color: #000 !important;
}

.gray {
  color: #b2b3b3 !important;
}
.blue {
  color: #00538b !important;
}

.blueT {
  color: #2a689457 !important;
}

.red {
  color: #d9002b !important;
}

.red2 {
  color: #cc4c4c !important;
}

.green {
  color: #60cb4c !important;
}

.cursor {
  cursor: pointer !important;
}

/* background */

.b-white {
  background: #fff !important;
}

.b-gray {
  background: #f0f2f5 !important;
}

.b-gray-t {
  background: #ffffffb8 !important;
}

.b-blue {
  background: #00538b !important;
}

.b-blueT {
  background: #2a689457 !important;
}

.b-red {
  background: #d9002b !important;
}

.b-red2 {
  background: #cc4c4c !important;
}

.b-green {
  background: #60cb4c !important;
}

/* border */

.br-gray {
  border-color: #f0f2f5 !important;
}

.br-blue {
  border-color: #00538b !important;
}

.br-blueT {
  border-color: #2a689457 !important;
}

.br-red {
  border-color: #d9002b !important;
}

.br-red2 {
  border-color: #cc4c4c !important;
}

.br-green {
  border-color: #60cb4c !important;
}

.radius {
  border-radius: 6px !important;
}

.circle {
  border-radius: 50px !important;
}

/* width */
.full {
  width: 100% !important;
}
.col-12 {
  width: 50%;
}

.col-11 {
  width: 45.83%;
}

.col-10 {
  width: 41.666%;
}

.col-9 {
  width: ‭37.5‬%;
}

.col-8 {
  width: 33.3333%;
}

.col-7 {
  width: 45.83%;
}

/* image */

.imageFull {
  max-height: 300px;
  object-fit: cover;
  width: 100%;
}

.imageHalf {
  max-height: 150px;
  width: 100%;
  object-fit: cover;
}

/* border */
.no-border {
  border: none !important;
}

/* float */
.fLeft {
  float: left !important;
}

.fright {
  float: right !important;
}

/* button */

.buttonSquere {
  width: 50px;
}

/* padding */

.no-padding {
  padding: 0px !important;
}
.padding10 {
  padding: 10px !important;
}

.paddingVer20 {
  padding-right: 20px !important;
  padding-left: 20px !important;
}

.paddingVer40 {
  padding-right: 40px !important;
  padding-left: 40px !important;
}
.paddingVer10 {
  padding-right: 10px !important;
  padding-left: 10px !important;
}

.paddingHor20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.padding20 {
  padding: 20px !important;
}

.marginCenter {
  margin: 0 auto !important;
}

/* margin */
.margin10 {
  margin: 10px;
}

.marginTop10 {
  margin-top: 10px !important;
}

.marginTop20 {
  margin-top: 20px !important;
}

.marginBot20 {
  margin-bottom: 20px !important;
}

.marginVer20 {
  margin-left: 20px;
  margin-right: 20px;
}

.marginHor20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.marginVer5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.marginHor10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.margin20 {
  margin: 20px !important;
}

.marginLeft5 {
  margin-left: 5px !important;
}

.marginLeft20 {
  margin-left: 20px !important;
}
.marginRight10 {
  margin-right: 10px !important;
}

.marginRight5 {
  margin-right: 5px !important;
}

.marginRight20 {
  margin-right: 20px !important;
}

.marginRight40 {
  margin-right: 40px !important;
}

/* font */
.bold {
  font-weight: bold;
}

.fw400 {
  font-weight: 400 !important;
}

.f1 {
  font-size: 25px;
}

.f2 {
  font-size: 20px;
}

.f3 {
  font-size: 18px;
}

.f4 {
  font-size: 14px;
}

.f5 {
  font-size: 10px;
}

.f16 {
  font-size: 16px;
}

.f20 {
  font-size: 20px;
}

.link {
  color: #0a60c1;
  text-decoration: underline;
}

/* align */
.textCenter {
  text-align: center !important;
}

.textLeft {
  text-align: left !important;
}

.textRight {
  text-align: right !important;
}

/* display */
.show {
  display: block !important;
}

.flex {
  display: flex !important;
}

.flex1 {
  flex: 1 1 0;
}



.column{
  flex-direction: column;
}

.ant-drawer-open button.ant-drawer-close {
  left: 0px;
  right: inherit;
}

.relative {
  position: relative !important;
}

.grid {
  display: grid !important;
}

.grid2 {
  grid-template-columns: 1fr 1fr;
}

.grid .image-grid {
  object-fit: cover;
  width: 100%;
  height: 200px;
  padding: 20px;
}

i.anticon.anticon-search.ant-table-filter-icon.ant-dropdown-trigger {
  color: white;
  font-size: 12px;
}

.contents {
  display: contents;
}

.hide {
  display: none !important;
}

/* modal */

.ant-modal-body {
  display: flow-root;
}

.ant-modal-body button.ant-modal-close,
.ant-modal-close-x {
  color: #fff;
}

.ant-tabs-tabpane.ant-tabs-tabpane-active.padding20 {
  padding: 0px !important;
}

.background-image {
  background-repeat: no-repeat;
  background-size: 100% 153%;
  position: relative;
  height: 98vh;
}

.ant-drawer-content-wrapper {
  width: 500px !important;
}

.screen {
  width: 100%;
  height: 100vh;
  opacity: 0.3;
  position: absolute;
  z-index: 2;
  background: white;
}

.main.home_page {
  position: relative;
  z-index: 1;
}

.payment-req button.ant-modal-close {
  display: none;
}
