.bar-precent:first-child {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.bar-precent:last-child {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.scroll-me {
  height: calc(100vh - 163px);
  overflow-y: scroll;
  margin-bottom: auto;
}
