.CardAddedFiles .ant-card {
  border: none;
}

.inline {
  display: inline-flex !important;
}

.hoverCard {
  width: 25%;
  height: 100px;
  position: absolute;
  left: 0;
  background: #47628482;
  color: #fff;
  font-size: 22px;
  text-align: center;
  line-height: 4.5;
}
